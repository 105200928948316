import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/ziggurat-web/ziggurat-web/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "middleware"
    }}>{`Middleware`}</h1>
    <hr></hr>
    <p>{`Ziggurat V3.0.0-alpha.3 (will be released in 3.0.0 stable version) introduced the support for middleware in Ziggurat.
In older versions of Ziggurat, the mapper-function passed to `}<inlineCode parentName="p">{`(ziggurat/init)`}</inlineCode>{` would get the message as a parameter which was an object of Clojure PersistentHashMap. Ziggurat assumed that all messages read from Kafka were serialized using Protobuf and it would thus deserialize the message internally.`}</p>
    <p>{`As the userbase of Ziggurat has grown and the problem space people are trying to tackle with Ziggurat has widened, we realize that this was a wrong assumption and so from this release Ziggurat will just pass the serialized message directly to the mapper-function.`}</p>
    <p>{`We have provided a default middleware that replicates the logic (deserialization of proto) and thus users can use and thus have backward compatibility. `}</p>
    <h2 {...{
      "id": "migration-from-2x---3x-for-middleware"
    }}>{`Migration from 2.x - 3.x for middleware`}</h2>
    <p>{`All you have to do to migrate to the newer version is update the mapper-function. So, for example`}</p>
    <p>{`Change`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clojure"
      }}>{`;; 2.x
(defn main-fn
    [message]
    (println message)
    :success)
  
(ziggurat/main start-fn stop-fn {:stream-id {:handler-fn main-fn}})
`}</code></pre>
    <p>{`to `}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clojure"
      }}>{`;; 3.x
(defn main-fn
    [message]
    (println message)
    :success)

(def handler-fn
    (-> main-fn
        (ziggurat.middleware.default/protobuf->hash protoClass :stream-id)))
  
(ziggurat/main start-fn stop-fn {:stream-id {:handler-fn handler-fn}})
`}</code></pre>
    <h2 {...{
      "id": "defining-your-own-middleware"
    }}>{`Defining your own Middleware`}</h2>
    <p>{`If you wish to define your own middleware function, ensure that the function follows these definition restrictions:`}</p>
    <ul>
      <li parentName="ul">{`The first argument should be a handler-function`}</li>
      <li parentName="ul">{`It should return a function that takes 1 argument: the message`}</li>
    </ul>
    <p>{`An example of a middleware that just logs a message:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clojure"
      }}>{`(defn wrap-log-message [handler-fn]
  (fn [message]
    (log/info message)
    (handler-fn message)))
`}</code></pre>
    <h2 {...{
      "id": "chaining-middleware"
    }}>{`Chaining Middleware`}</h2>
    <p>{`You can also chain multiple middleware functions to incorporate different behaviours. So for example if you wish to deserialize a message and then log it we can chain these middlewares on wrap your mapper-fn with them.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-clojure"
      }}>{`;; protobuf->hash is a default middleware that Ziggurat gives to deserialize proto
(def handler-fn
  (-> mapper-fn
      (wrap-log-message)
      (ziggurat.middleware.default/protobuf->hash protoClass :stream-id)))
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      